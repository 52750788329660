import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://a651aff6ff72483fb81abd08b5193297@o936488.ingest.us.sentry.io/4504811577344000",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});